import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ColorSchemeId, ColorSchemeSpec, FontId, FontSizePoint, ModeId} from "./constants";
import Color from "color";

// See modalSlice for the template comments for a reducer slice

export interface TextFormatterState {
  userInput: string;
  mode: ModeId;
  font: FontId;
  fontSize: FontSizePoint;
  selectedColorScheme: ColorSchemeId;
  // colorSchemeSpec: ColorSchemeSpec;
}

const initialState: TextFormatterState = {
  userInput: '',
  mode: ModeId.Input,
  font: FontId.OpenDyslexicRegular,
  fontSize: 14,
  selectedColorScheme: ColorSchemeId.AlternatingLetters,
  // colorSchemeSpec: {
  //   background: Color('white'),
  //   foreground: Color('black'),
  // }
};

export const textFormatterSlice = createSlice({
  name: 'textFormatter',
  initialState,
  reducers: {
    setFont: (state, action: PayloadAction<FontId>) => {
      state.font = action.payload;
    },
    setFontSize: (state, action: PayloadAction<FontSizePoint>) => {
      state.fontSize = action.payload;
    },
    setColorSchemeId: (state, action: PayloadAction<ColorSchemeId>) => {
      state.selectedColorScheme = action.payload;
    },
    setMode: (state, action: PayloadAction<ModeId>) => {
      state.mode = action.payload;
    },
    setInput: (state, action: PayloadAction<string>) => {
      state.userInput = action.payload;
    },
  },
});

export const {
  setFont,
  setFontSize,
  setColorSchemeId,
  setMode,
  setInput,
} = textFormatterSlice.actions;

export default textFormatterSlice.reducer;

import React from 'react';
import './App.css';
import {Route, Switch} from 'react-router-dom';
import {TextFormatter} from "./features/textFormatter/TextFormatter";

export enum AppRoute {
  Home = '/',
}

const App = () => (
  <Switch>
    <Route exact path={AppRoute.Home.valueOf()} component={TextFormatter}/>
  </Switch>
);

export default App;

import {createSlice, PayloadAction} from "@reduxjs/toolkit";
// ------------------------------------
// Constants
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------

export enum ModalActions {
  SetOpen = 'modal/setOpen',
  SetClosed = 'modal/setClosed',
  UserClose = 'modal/userClose',
  SetProperties = 'modal/setProperties',
}

export enum SagaModalActions {
  ConfirmSaga = 'modal/ConfirmSaga',
  YesNoSaga = 'modal/YesNoSaga',
  InputSaga = 'modal/InputSaga',
  OutputSaga = 'modal/OutputSaga',
}

export enum ModalType {
  Confirm = '@@modalType/Confirm',
  YesNo = '@@modalType/YesNo',
  Input = '@@modalType/Input',
  Output = '@@modalType/Output',
}

interface ModalAction {
  type: ModalActions;
}

interface ModalSagaAction {
  type: SagaModalActions;
}

export interface ModalCloseResult {
  type: ModalType;
  cancelled: boolean;
  confirmed: boolean;
}

export interface ConfirmResult extends ModalCloseResult {
  type: ModalType.Confirm;
}

export interface YesNoResult extends ModalCloseResult {
  type: ModalType.YesNo;
  no: boolean;
}

export interface InputResult extends ModalCloseResult {
  type: ModalType.Input;
  input: string;
}

export interface OutputResult extends ModalCloseResult {
  type: ModalType.Output;
}

export interface UserCloseAction extends ModalAction {
  type: ModalActions.UserClose;
  result: ModalCloseResult;
}

export interface SetPropertiesAction extends ModalAction {
  type: ModalActions.SetProperties;
  modalType: ModalType;
  title: string;
  descriptionText: string | null;
  confirmButtonLabel: string | null;
  yesButtonLabel: string | null;
  noButtonLabel: string | null;
  outputText: string | null;
}

export const setPropertiesAction = (
  modalType: ModalType,
  title: string,
  descriptionText: string | null,
  confirmButtonLabel: string | null,
  yesButtonLabel: string | null,
  noButtonLabel: string | null,
  outputText: string | null,
): SetPropertiesAction => {
  return {
    type: ModalActions.SetProperties,
    modalType,
    title,
    descriptionText,
    confirmButtonLabel,
    yesButtonLabel,
    noButtonLabel,
    outputText,
  };
};

export interface ConfirmSagaAction extends ModalSagaAction {
  type: SagaModalActions.ConfirmSaga;
  title: string;
  text: string;
  buttonLabel: string;
}

export const confirmSaga = (title: string, text: string, buttonLabel: string = 'Ok'): ConfirmSagaAction => {
  return {
    type: SagaModalActions.ConfirmSaga,
    title,
    text,
    buttonLabel,
  };
};

export interface YesNoSagaAction extends ModalSagaAction {
  type: SagaModalActions.YesNoSaga;
  title: string;
  text: string;
  yesButtonLabel: string;
  noButtonLabel: string;
}

export const yesNoSaga = (title: string, text: string, yesButtonLabel: string = 'Yes', noButtonLabel: string = 'No'): YesNoSagaAction => {
  return {
    type: SagaModalActions.YesNoSaga,
    title,
    text,
    yesButtonLabel,
    noButtonLabel,
  };
};

export interface InputSagaAction extends ModalSagaAction {
  type: SagaModalActions.InputSaga;
  title: string;
  text: string;
  buttonLabel: string;
}

export const inputSaga = (title: string, text: string, buttonLabel: string): InputSagaAction => {
  return {
    type: SagaModalActions.InputSaga,
    title,
    text,
    buttonLabel,
  };
};

export interface OutputSagaAction extends ModalSagaAction {
  type: SagaModalActions.OutputSaga;
  title: string;
  text: string;
  output: string;
  buttonLabel: string;
}

export const outputSaga = (title: string, text: string, output: string, buttonLabel: string): OutputSagaAction => {
  return {
    type: SagaModalActions.OutputSaga,
    title,
    text,
    output,
    buttonLabel,
  };
};

export interface ModalState {
  modalType: ModalType;
  isOpen: boolean;
  title: string;
  descriptionText: string | null;
  confirmButtonLabel: string | null;
  yesButtonLabel: string | null;
  noButtonLabel: string | null;
  outputText: string | null;
  result: ModalCloseResult | null;
}

const initialState: ModalState = {
  modalType: ModalType.Confirm,
  isOpen: false,
  title: 'Modal',
  descriptionText: null,
  confirmButtonLabel: null,
  yesButtonLabel: null,
  noButtonLabel: null,
  outputText: null,
  result: null,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setOpen: state => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.isOpen = true;
    },
    setClosed: state => {
      state.isOpen = false;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    userClose: (state, action: PayloadAction<ModalCloseResult>) => {
      state.result = action.payload;
    },
    setProperties: (state, action: PayloadAction<SetPropertiesAction>) => {
      state.modalType = action.payload.modalType;
      state.title = action.payload.title;
      state.descriptionText = action.payload.descriptionText;
      state.confirmButtonLabel = action.payload.confirmButtonLabel;
      state.yesButtonLabel = action.payload.yesButtonLabel;
      state.noButtonLabel = action.payload.noButtonLabel;
      state.outputText = action.payload.outputText;
    },
  },
});

export const {setOpen, setClosed, userClose, setProperties} = modalSlice.actions;

export default modalSlice.reducer;

import {PROJECT_NAME} from "./constants";

export enum LogLevels {
  Error = '@@logLevel/Error',
  Warn = '@@logLevel/Warn',
  Info = '@@logLevel/Info',
  Debug = '@@logLevel/Debug',
  Trace = '@@logLevel/Trace',
}

const LOG_LEVEL_LOCAL_STORAGE_KEY = `${PROJECT_NAME}.log_level`;
const logLevel = window.localStorage.getItem(LOG_LEVEL_LOCAL_STORAGE_KEY) as LogLevels || LogLevels.Debug;
const heirarchy = [LogLevels.Trace, LogLevels.Debug, LogLevels.Info, LogLevels.Warn, LogLevels.Error];

export const log = (message: string, level = LogLevels.Info) => {
  let levelIndex = heirarchy.indexOf(logLevel);
  if (levelIndex < 0) {
    throw new Error(`Invalid explicit log level ${logLevel}. Please use a valid value or clear ${LOG_LEVEL_LOCAL_STORAGE_KEY} from local storage.`);
  }

  if (heirarchy.indexOf(level) >= levelIndex) {
    switch (level) {
      case LogLevels.Info:
      case LogLevels.Debug:
      case LogLevels.Trace:
        console.info(`${message}`);
        return;
      case LogLevels.Warn:
        console.warn(`${message}`);
        return;
      case LogLevels.Error:
        console.error(`${message}`);
        return;
    }
  }
};
import React, {CSSProperties, Dispatch, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ModalContainer} from "../../lib/components/ModalContainer";
import {RootState} from "../../app/store";
import {Color as rcColor, ColorChangeHandler, SliderPicker} from "react-color";
import {setColorSchemeId, setFont, setFontSize, setInput, setMode} from './textFormatterSlice';
import {log, LogLevels} from "../../lib/logger";
import Color from "color";
import styles from './TextFormatter.module.css';
import {Dropdown, DropdownButton, Form, FormControl, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import {
  ColorSchemeDefinitions,
  ColorSchemeId,
  FontDefinitions,
  FontId,
  FontSizePoint,
  ModeDefinitions,
  ModeId
} from "./constants";

export function TextFormatter() {
  const viewportHeight = window.innerHeight;
  // TODO: something better
  const inputRows = Math.floor(viewportHeight / 50);

  const dispatch = useDispatch();
  const mode = useSelector((state: RootState) => state.textFormatterReducer.mode) as ModeId;
  const font = useSelector((state: RootState) => state.textFormatterReducer.font) as FontId;
  const fontSize = useSelector((state: RootState) => state.textFormatterReducer.fontSize) as FontSizePoint;
  const colorSchemeId = useSelector((state: RootState) => state.textFormatterReducer.selectedColorScheme) as ColorSchemeId;
  const userInput = useSelector((state: RootState) => state.textFormatterReducer.userInput) as string;

  //  create array of colors and setters to pass into render functions
  const [color1, setColor1] = useState(Color.rgb(0, 0, 0));
  const [color2, setColor2] = useState(Color.rgb(0, 0, 0));
  const [color3, setColor3] = useState(Color.rgb(0, 0, 0));

  return (
    <>
      <ModalContainer/>
      <div className={styles.textFormatterContainer}>
        <div className={`${styles.userTextContainer} ${mode === ModeId.Input ? styles.modeInput : styles.modeOutput}`}>
          {renderUserText(
            dispatch,
            mode,
            userInput,
            inputRows,
            font,
            fontSize,
            colorSchemeId,
            color1,
            color2,
            color3,
          )}
        </div>
        <Form className={styles.sidebarContainer}>
          <Form.Group className={styles.sidebarSection}>
            <Form.Label>Mode</Form.Label>
            <br/>
            <ToggleButtonGroup
              type="radio"
              name="options"
              value={mode}
              onChange={(mode) => dispatch(setMode(mode))}
            >
              {Object.values(ModeDefinitions).map((modeDefinition) => {
                return <ToggleButton
                  key={`mode-toggle-${modeDefinition.id}`}
                  value={modeDefinition.id}
                >
                  {modeDefinition.displayName}
                </ToggleButton>
              })}
            </ToggleButtonGroup>
          </Form.Group>
          <Form.Group className={styles.sidebarSection}>
            <Form.Label>Font</Form.Label>
            <DropdownButton id="font-dropdown" title={FontDefinitions[font].displayName}>
              {Object.values(FontDefinitions).map((fontDefinition) => {
                return <Dropdown.Item
                  key={`font-option-${fontDefinition.id}`}
                  eventKey={fontDefinition.id}
                  active={font === fontDefinition.id}
                  onSelect={(key) => {
                    if (!key) {
                      log(`Invalid font dropdown key ${key}`, LogLevels.Warn);
                      return;
                    }
                    dispatch(setFont(key as FontId));
                  }}
                >{fontDefinition.displayName}</Dropdown.Item>
              })}
            </DropdownButton>
          </Form.Group>
          <Form.Group className={styles.sidebarSection}>
            <Form.Label>Font Size</Form.Label>
            <Form.Control
              className={styles.fontSize}
              type="number"
              value={fontSize}
              onChange={(newValue) => {
                dispatch(setFontSize(parseInt(newValue.target.value, 10)))
              }}
            />
          </Form.Group>
          <Form.Group className={styles.sidebarSection}>
            <Form.Label>Color Scheme Type</Form.Label>
            <DropdownButton id="color-scheme-dropdown" title={ColorSchemeDefinitions[colorSchemeId].displayName}>
              {Object.values(ColorSchemeDefinitions).map((colorSchemeDefinition) => {
                return <Dropdown.Item
                  key={`color-scheme-option-${colorSchemeDefinition.id}`}
                  eventKey={colorSchemeDefinition.id}
                  active={colorSchemeId === colorSchemeDefinition.id}
                  onSelect={(key) => {
                    if (!key) {
                      log(`Invalid color scheme dropdown key ${key}`, LogLevels.Warn);
                      return;
                    }
                    dispatch(setColorSchemeId(key as ColorSchemeId));
                  }}
                >{colorSchemeDefinition.displayName}</Dropdown.Item>
              })}
            </DropdownButton>
          </Form.Group>
          <Form.Group className={styles.sidebarSection}>
            <Form.Label>Colors</Form.Label>
            {renderColorSchemePickers(
              colorSchemeId,
              dispatch,
              [color1, color2, color3],
              [setColor1, setColor2, setColor3],
            )}
          </Form.Group>
        </Form>
      </div>
    </>
  );
}

/**
 * Text Formatting
 */

const renderUserText = (
  dispatch: Dispatch<any>,
  mode: ModeId,
  userInput: string,
  inputRows: number,
  font: FontId,
  fontSize: FontSizePoint,
  colorSchemeId: ColorSchemeId,
  color1: Color,
  color2: Color,
  color3: Color,
) => {
  switch (mode) {
    case ModeId.Input:
      return <Form>
        <Form.Group controlId="userInputArea">
          <Form.Control
            className={styles.userInputArea}
            as="textarea"
            // rows={inputRows}
            value={userInput}
            onChange={(e) => {
              dispatch(setInput(e.target.value));
            }}
          />
        </Form.Group>
      </Form>;
    case ModeId.Output:
      return <p className={styles.outputParagraph}>
        {formatOutputText(
          userInput,
          font,
          fontSize,
          colorSchemeId,
          [color1, color2, color3],
        )}
      </p>;
  }
};

/**
 * Format text into HTML for display in the output pane.
 */
const formatOutputText = (
  text: string,
  font: FontId,
  fontSize: FontSizePoint,
  colorSchemeId: ColorSchemeId,
  colors: Array<Color>,
) => {
  switch (colorSchemeId) {
    case ColorSchemeId.Default:
      setStyleCss(1, font, fontSize, colors[0]);
      return <span className='outputClass1'>
        {text}
      </span>
    case ColorSchemeId.AlternatingLetters:
      setStyleCss(1, font, fontSize, colors[0]);
      setStyleCss(2, font, fontSize, colors[1]);
      // Tracks the number of non-whitespace characters seen so far.
      // Alternating color scheme does not include whitespace.
      let characterIndex = 0;
      return text.split('').map((char, index) => {
        // TODO: other whitespace characters
        if (char !== ' ') {
          characterIndex++;
        }
        const isEven = (characterIndex % 2) === 0;
        return <span
          key={`outputKey${index}`}
          className={`outputClass${isEven ? 1 : 2}`}
        >
          {char}
        </span>
      });
  }
}

/**
 * @param index Should be 1/2/3 to set outputStyle1/2/3 corresponding to .outputClass1/2/3 and color1/2/3 etc.
 * @param font Font family
 * @param fontSize
 * @param textColor
 */
const setStyleCss = (
  index: number,
  font: FontId,
  fontSize: FontSizePoint,
  textColor: Color,
) => {
  const styleElement = document.getElementById(`outputStyle${index}`);
  if (!styleElement) {
    throw new Error(`Failed to find style element with id outputStyle${index}`);
  }
  styleElement.innerHTML = getStyleCss(index, font, fontSize, textColor);
}

const getStyleCss = (
  index: number,
  font: FontId,
  fontSize: FontSizePoint,
  textColor: Color,
): string => {
  return `span.outputClass${index}
  {
    font-family: ${FontDefinitions[font].fontFamily};
    font-size: ${fontSize}pt;
    color: ${textColor.hex()};
  }`;
}

// const getFormattedTextStyles = (font: FontId): CSSProperties => {
//   return {
//     fontFamily: FontDefinitions[font].fontFamily,
//   }
// };

/**
 * Color picker stuff
 */
const renderColorSchemePickers = (
  scheme: ColorSchemeId,
  dispatch: Dispatch<any>,
  colors: Array<Color>,
  colorSetters: Array<(value: Color) => void>,
) => {
  switch (scheme) {
    case ColorSchemeId.Default:
      return <>
        {renderColorPicker(colors[0].hex(), (color) => colorSetters[0](Color(color.hex)))}
      </>
    case ColorSchemeId.AlternatingLetters:
      return <>
        <div className={styles.colorPickerContainer}>
          {renderColorPicker(colors[0].hex(), (color) => colorSetters[0](Color(color.hex)))}
        </div>
        <div className={styles.colorPickerContainer}>
          {renderColorPicker(colors[1].hex(), (color) => colorSetters[1](Color(color.hex)))}
        </div>
      </>
  }
};

const renderSelectedColor = (color: Color) => {

};

const renderColorPicker = (value: rcColor, changeHandler: ColorChangeHandler) => {
  return <SliderPicker
    color={value}
    onChangeComplete={changeHandler}
  />
};

import Color from "color";


export enum ModeId {
  Input = '@@formatterMode/input',
  Output = '@@formatterMode/output',
}

export interface ModeDefinition {
  id: ModeId;
  displayName: string;
}

export const ModeDefinitions: Record<ModeId, ModeDefinition> = {
  [ModeId.Input]: {
    id: ModeId.Input,
    displayName: "Input",
  },
  [ModeId.Output]: {
    id: ModeId.Output,
    displayName: "Formatted",
  },
};

export type FontSizePoint = number;

export enum FontId {
  Default = '@@font/default',
  OpenDyslexicRegular = '@@font/openDyslexicRegular',
  OpenDyslexicBold = '@@font/openDyslexicBold',
}

export interface FontDefinition {
  id: FontId;
  displayName: string;
  fontFamily: string;
}

export const FontDefinitions: Record<FontId, FontDefinition> = {
  [FontId.Default]: {
    id: FontId.Default,
    displayName: "Default",
    fontFamily: "Helvetica Neue",
  },
  [FontId.OpenDyslexicRegular]: {
    id: FontId.OpenDyslexicRegular,
    displayName: "Open Dyslexic Regular",
    fontFamily: "OpenDyslexic3Regular",
  },
  [FontId.OpenDyslexicBold]: {
    id: FontId.OpenDyslexicBold,
    displayName: "Open Dyslexic Bold",
    fontFamily: "OpenDyslexic3Bold",
  },
};

export enum ColorSchemeId {
  Default = '@@colorScheme/default',
  AlternatingLetters = '@@colorScheme/alternatingLetters',
}

export interface ColorSchemeDefinition {
  id: ColorSchemeId;
  displayName: string;
}

export const ColorSchemeDefinitions: Record<ColorSchemeId, ColorSchemeDefinition> = {
  [ColorSchemeId.Default]: {
    id: ColorSchemeId.Default,
    displayName: "Default",
  },
  [ColorSchemeId.AlternatingLetters]: {
    id: ColorSchemeId.AlternatingLetters,
    displayName: "Alternating Letters",
  },
};

export interface DefaultColorScheme {
  foreground: Color;
  background: Color;
}

export interface AlternatingLetterColorScheme {
  foregroundA: Color;
  foregroundB: Color;
  background: Color;
}

// Use a type union here because each color scheme definition is distinct and we basically just want
//  to switch in each case
export type ColorSchemeSpec = DefaultColorScheme | AlternatingLetterColorScheme;
